<template>
    <div v-if='model && !model.is_null'>
        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12 md:col-6'>
                    <div class="p-inputgroup">
                    <span class='p-float-label'>
                        <InputText id='plain_slug'
                                   type='text'
                                   v-model="v$.model.slug.$model"
                                   :class="{'p-invalid': v$.model.slug.$errors.length}"
                        />
                        <Button label='Fix' class="p-button-warning" title='Correggi SLUG' @click='model.slug = sluggify(model.slug)'/>
                        <label for='plain_slug'>Slug</label>
                    </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='name'
                                   type='text'
                                   v-model="v$.model.name.$model"
                                   :class="{'p-invalid': v$.model.name.$errors.length}"
                        />
                        <label for='name'>Nome</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='uLogo'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='logo'
                                   type='text'
                                   v-model="model.logo"
                        />
                        <label for='logo'>Logo</label>
                    </span>
                    <div v-if='model.logo' class='text-center'>
                        <img :src='model.logo' alt='Model logo' :height='uppyDashboardProps.height-30'/>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid mt-3'>
                <div class='col-12'>
                    <TabView>
                        <TabPanel v-for='(line, ll) in languages' :key="'co_tr_'+ll" >
                            <template #header>
                                <Flag :code="ll" :name="line.label" :width='16' :class="{'mr-1': true}"/>
                                <span>{{line.label}}</span>
                            </template>
                            <div class='grid p-fluid formgrid mt-3'>
                                <div class='field col-12'>
                                    <span class='p-float-label'>
                                        <InputText :id="'claim_'+ll"
                                                   type='text'
                                                   v-model="model.translations[ll]['claim']"
                                        />
                                        <label :for="'claim_'+ll">Claim {{line.label}}</label>
                                    </span>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Users/Companies');
const commonStore = createNamespacedHelpers('Common');
import useVuelidate from '@vuelidate/core'

import model from './model';

import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import Uploader from '../../../mixins/uploader';

export default {
    mounted(){
        console.log("LANGS", this.languages);
        this.initModel(this.row);
        this.prepareUploads(model.uploads, this.lang);
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.modelValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
        ...commonStore.mapGetters(['languages']),
    },
    watch: {
        row(n){
            this.initModel(n);
        }
    },
    methods: {
        ...mapActions(['savePlain','createPlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }
            const save = (this.model.id > 0) ? this.savePlain : this.createPlain;
            const changeRoute = (this.model.id === 0);

            save(this.model).then( resp => {
                this.formIsClean();
                if (changeRoute){
                    this.navigateToId(resp.id).then( () => {
                        this.ShowSuccess('Ottimo', 'Azienda creata con successo');
                    });
                    return;
                }
                this.ShowSuccess('Ottimo', 'Azienda modificata con successo');

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initModel(row){
            if (!row) {
                return;
            }
            this.model = row;
            if (!this.model.translations) this.model.translations = [];
            Object.keys(this.languages).forEach(l => {
                if (!this.model.translations[l]) this.model.translations[l] = [];
                if (!this.model.translations[l]['claims']) this.model.translations[l]['claims'] = "";
            });
        }
    },
    mixins: [
        Navigations,
        Notifications,
        Uploader
    ]
};
</script>
