import tools from '../../../libs/tools';
import { required } from '@vuelidate/validators'

const model = {
    id: 0,
    name: "",
    slug: "",
    logo: "",
    translations: {},
};

const translation = {
    claim: "",
    company_id: 0,
};

export default {
    ...tools.models.apiCommons(model, translation, "company_id"),
    modelValidation: {
        model: {
            name: {
                required
            },
            slug: {
                required,
                slug: tools.models.validators.SLUG
            }
        }
    },
    translationValidation: {
        model: {}
    },
    uploads: [
        { el: "uLogo", target: "logo", folder: "/images/companies/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
    ]
};
